<template>
  <v-container fluid class="full-fill pa-0">
    <v-row class="full-fill">
      <!-- Search -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
        <Search />
      </v-col>
      <!-- Content -->
      <v-col align="center" class="col-sm-7 ma-0 pa-5 pl-5 pr-5 pl-sm-0 pr-sm-0">
        <v-row class="full-fill">
          <v-col align="center" class="ma-0 pa-0">
            <v-card class="full-fill">
              <h1>About Us</h1>
              <h4>Know the troops.</h4>
              <h4>Support the troops.</h4>
              <v-img class="mb-5 mt-5" src="@/assets/img/about-hero.jpg"></v-img>
              <iframe class="d-none d-sm-flex" width="600" height="400" src="https://www.youtube.com/embed/Yd84hpv0V2E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <iframe class="d-flex d-sm-none" width="300" height="200" src="https://www.youtube.com/embed/Yd84hpv0V2E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <div class="pa-5">
                <p>Honor Tag provides a way to create a virtual profile of a U.S. veteran. We call that profile a Story.</p>
                <p>One brand, all branches. Honor Tag will provide a united platform to memorialize and pay respects to those lost and missing, and to say thank you to our living veterans. As of 2014, the U.S. government estimates there are 43 million deceased veterans; 22 million living veterans; and 83,000 classified as missing or prisoners of war.</p>
                <p>Honor Tag places the veteran at the heart of the military experience. Through personal narratives, peer tributes, media and other data points, Honor Tag will reveal the many facets of a service member's experience.</p>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-2"></v-row>
        <v-row class="full-fill">
          <v-col class="ma-0 pa-0">
            <v-card class="blue lighten-4 mb-10 pa-5 text-left">
              <h2 class="mb-3">Danielle Troyan, Founder</h2>
              <p><v-img max-width="300" class="float-left mb-5 mb-sm-0 mr-5" src="@/assets/img/danielle.jpg"></v-img>The daughter of a U.S. Army veteran and a native-born French teacher, Danielle Troyan followed in her parents’ footsteps of service. She is a trustee and a past alumni association president of St. Mary’s College of Maryland, a nationally recognized public liberal arts college committed to affordability, accessibility, diversity and inclusion. Danielle is also a commissioner of Historic St. Mary’s City, the site of the fourth oldest permanent English settlement in North America, and a place of historical significance to civil rights and representative government.</p>
              <p>A former managing director of a start-up tech firm and current member of the social entrepreneurship community, Danielle brings her drive and know-how to nonprofit management. As Vice President of External Relations for the Business-Higher Education Forum (BHEF), she works with Fortune 500 CEOs and university presidents to create digital credentials at scale that drive economic equality through inclusive career pathways for the under- and unemployed.</p>
              <p>As Director of Operations at the United Service Organizations (USO), Danielle ensured America’s military and their families received the highest quality of service at centers worldwide. She launched the Mobile USO, which reached over 100,000 troops and was seen by over 4 million people its first year. Danielle managed activities surrounding new center openings, to include the first USO facility in Iraq. She rebuilt the USO’s volunteer division, which includes more than 26,000 unpaid staff.</p>
              <p>Danielle directed external relations for MENTOR, a national organization that supports more than 5,000 youth mentoring programs. She began her nonprofit career as a staff member for three CEOs of the American Red Cross.</p>
              <p>Danielle is an alumna of the Harvard Business School, received her M.A. in Communications from The University of Oklahoma and holds B.A.s in History and Political Science from St. Mary’s College of Maryland.</p>
            </v-card>
          </v-col>
        </v-row>
        <!-- Social Media list -->
        <div>
          <a href="https://www.facebook.com/honortagus/" target="_blank">
            <i class="center fab fa-facebook-f fa-2x pa-2"></i>
          </a>
          <a href="https://twitter.com/HonorTagUS" target="_blank">
            <i class="center fab fa-twitter fa-2x pa-2"></i>
          </a>
          <a href="https://www.linkedin.com/company/honor-tag-us/" target="_blank">
            <i class="center fab fa-linkedin-in fa-2x pa-2"></i>
          </a>
          <a href="https://www.instagram.com/honortag/" target="_blank">
            <i class="center fab fa-instagram fa-2x pa-2"></i>
          </a>
        </div>
      </v-col>
      <!-- Ads -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Search from '@/components/Search'

  export default {
    name: 'About',

    components: {
      Search
    }
  }
</script>

<style scoped>
  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>
